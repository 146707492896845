<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <v-card
            rounded
            dark
          >
            <v-window>
              <v-tabs
                v-model="tab"
                align-tabs="left"
              >
                <v-tab>
                  Upcoming
                </v-tab>
                <v-tab>
                  Past
                </v-tab>
              </v-tabs>
            </v-window>
            <v-card-text>
              <v-window>
                <v-window-item
                  v-for="n in 2"
                  :key="n"
                  :value="n"
                >
                  <v-container
                    v-if="tab == 0"
                  >
                    <v-row
                      v-for="item in upcomingShows"
                      :key="item.key"
                      :class="item.key > 1 ? 'mt-3': 'mt-0'"
                    >
                      <v-col>
                        <div>
                          <span
                            class="font-weight-bold text-no-wrap"
                            style="color: #E1AE05"
                          >
                            {{ item.date }} ({{ item.startTime }})
                          </span>
                          <a :href="item['venue-url']">
                            <v-img
                              :src="item['venue-image-src']"
                              :title="item['hover-message']"
                            />
                          </a>
                        </div>
                        <div>
                          <span
                            class="text-medium-emphasis text-no-wrap"
                            style="color: #fff"
                          >
                            {{ item['venue-title'] }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-container
                    v-if="tab == 1"
                  >
                    <v-row
                      v-for="item in pastShows"
                      :key="item.key"
                      :class="item.key > 1 ? 'mt-3': 'mt-0'"
                    >
                      <v-col>
                        <div>
                          <span
                            class="text-no-wrap"
                            style="color: #E1AE05"
                          >
                            {{ item.date }}
                          </span>
                          <span
                            class="text-no-wrap"
                            style="color: #fff"
                          >
                            <a :href="item['venue-url']">
                              <v-img
                                :src="item['venue-image-src']"
                                :title="item['venue-title']"
                                align-content="center"
                              />
                            </a>
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-window-item>
              </v-window>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  export default {
    data: () => ({
      tab: '',
      items: [
        'upcoming', 'past'
      ],
      upcomingShows: [
        {
          'key': 3,
          'date': 'Sept 14th, 2024',
          'startTime': '8:00pm',
          'endTime': '11:00pm',
          'venue-image-src': '/static/old-nicks-pub-arcadia.png',
          'venue-url': 'https://www.facebook.com/ONPJMR',
          'venue-calendar-file': '/static/calendar/oldnickspub-20240914.ics',
          'venue-title': 'Old Nick\'s Pub - Arcadia',
          'hover-message': 'Click for more info'
        },
        {
          'key': 4,
          'date': 'Sept 28th, 2024',
          'startTime': '6:00pm',
          'endTime': '9:00pm',
          'venue-image-src': '/static/tpc.jpg',
          'venue-url': 'https://www.facebook.com/tanglewoodpizza/',
          'venue-calendar-file': '/static/calendar/tanglewood-20240928.ics',
          'venue-title': 'Tanglewood Pizza Company',
          'hover-message': 'Click for more info'
        },
        {
          'key': 5,
          'date': 'Oct 5th, 2024',
          'startTime': '8:00pm',
          'endTime': '9:00pm',
          'venue-image-src': '/static/carolina-classic-fair.jpg',
          'venue-url': 'https://www.facebook.com/carolinaclassicfair',
          'venue-calendar-file': '/static/calendar/carolina-classic-fair-20241005.ics',
          'venue-title': 'Carolina Classic Fair',
          'hover-message': 'Click for more info'
        },
        {
          'key': 6,
          'date': 'Nov 16th, 2024',
          'startTime': '8:30pm',
          'endTime': '11:30pm',
          'venue-image-src': '/static/starlight.png',
          'venue-url': 'https://starlighton22nd.com',
          'venue-calendar-file': '/static/calendar/starlight-20241116.ics',
          'venue-title': 'Starlight on 22nd',
          'hover-message': 'Click for more info'
        }
      ],
      pastShows: [
        {
          'key': 21,
          'date': 'August 30th, 2024',
          'startTime': '8:00pm',
          'endTime': '11:00pm',
          'venue-image-src': '/static/gas-hill-drinking-room.jpg',
          'venue-url': 'https://www.etix.com/ticket/p/74249808/goryango-lemon-sparks-the-trick-threat-winstonsalem-the-ramkat',
          'venue-calendar-file': '/static/calendar/gas-hill-20240830.ics',
          'venue-title': 'Gas Hill Drinking Room',
          'hover-message': 'Click for more info'
        },
        {
          'key': 20,
          'date': 'August 9th, 2024',
          'startTime': '8:00pm',
          'endTime': '11:00pm',
          'venue-image-src': '/static/millers.jpg',
          'venue-url': 'https://www.facebook.com/millersontradews',
          'venue-calendar-file': '/static/calendar/millers-20240809.ics',
          'venue-title': 'Miller\'s on Trade',
          'hover-message': 'Click for more info'
        },
        {
          'key': 19,
          'date': 'July 5th, 2024',
          'startTime': '8:00pm',
          'endTime': '11:00pm',
          'venue-image-src': '/static/millers.jpg',
          'venue-url': 'https://www.facebook.com/millersontradews',
          'venue-calendar-file': '/static/calendar/millers-20240705.ics',
          'venue-title': 'Miller\'s on Trade',
          'hover-message': 'Click for more info'
        },
        {
          'key': 18,
          'date': 'May 17th, 2024',
          'startTime': '6:00pm',
          'endTime': '9:00pm',
          'venue-image-src': '/static/tpc.jpg',
          'venue-url': 'https://www.facebook.com/tanglewoodpizza/',
          'venue-calendar-file': '/static/calendar/tanglewood-20240517.ics',
          'venue-title': 'Tanglewood Pizza Company',
          'hover-message': 'Click for more info'
        },
        {
          'key': 17,
          'date': 'May 11th, 2024',
          'startTime': '8:30pm',
          'endTime': '11:30pm',
          'venue-image-src': '/static/starlight.png',
          'venue-url': 'https://starlighton22nd.com/event/bella-moulden/',
          'venue-calendar-file': '/static/calendar/starlight-20240511.ics',
          'venue-title': 'Starlight on 22nd',
          'hover-message': 'Click for more info'
        },
        {
          'key': 16,
          'date': 'March 2nd, 2024',
          'startTime': '6:00pm',
          'endTime': '9:00pm',
          'venue-image-src': '/static/tpc.jpg',
          'venue-url': 'https://www.facebook.com/tanglewoodpizza/',
          'venue-calendar-file': '/static/calendar/tanglewood-20240302.ics',
          'venue-title': 'Tanglewood Pizza Company',
          'hover-message': 'Click for more info'
        },
        {
          'key': 15,
          'date': 'February 16th, 2024',
          'startTime': '8:00pm',
          'endTime': '11:00pm',
          'venue-image-src': '/static/gas-hill-drinking-room.jpg',
          'venue-url': 'https://www.etix.com/ticket/p/36881008/goryango-secret-monkey-weekend-the-trick-threat-winstonsalem-the-ramkat',
          'venue-calendar-file': '/static/calendar/gas-hill-20240217.ics',
          'venue-title': 'Gas Hill Drinking Room',
          'hover-message': '>> Click here to Buy Tickets <<'
        },
        {
          'key': 14,
          'date': 'Dec 22nd, 2023',
          'startTime': '8:00pm',
          'endTime': '12:00am',
          'venue-image-src': '/static/1614.jpg',
          'venue-url': 'https://www.facebook.com/1614DMB',
          'venue-calendar-file': '/static/calendar/1614-20231222.ics',
          'venue-title': '1614'
        },
        {
          'key': 13,
          'date': 'Dec 16th, 2023',
          'startTime': '8:00pm',
          'endTime': '12:00am',
          'venue-image-src': '/static/old-nicks-pub-arcadia.png',
          'venue-url': 'https://www.facebook.com/ONPJMR',
          'venue-calendar-file': '/static/calendar/oldnickspub-20231216.ics',
          'venue-title': 'Old Nick\'s Pub - Arcadia'
        },
        {
          'key': 12,
          'date': 'Nov. 8th, 2023',
          'startTime': '8:00pm',
          'endTime': '11:00pm',
          'venue-image-src': '/static/gas-hill-drinking-room.jpg',
          'venue-url': 'https://www.etix.com/ticket/p/68709502/joelton-mayfield-goryango-brown-mountain-lightning-bugs-winstonsalem-the-ramkat',
          'venue-calendar-file': '/static/calendar/gas-hill-20231108.ics',
          'venue-title': 'Gas Hill Drinking Room'
        },
        {
          'key': 11,
          'date': 'Oct 7th, 2023',
          'startTime': '8:00pm',
          'endTime': '11:00pm',
          'venue-image-src': '/static/gas-hill-drinking-room.jpg',
          'venue-url': 'https://www.etix.com/ticket/p/92618713/goryango-secret-monkey-weekend-winstonsalem-the-ramkat?fbclid=IwAR0oJtQKjpP4rH_wUpEpOES4G1yPMnqmB1SUvgk729Noy9tIus0YSmj0HVU',
          'venue-calendar-file': '/static/calendar/gas-hill-20231007.ics',
          'venue-title': 'Gas Hill Drinking Room'
        },
        {
          'key': 10,
          'date': 'Sept 30th, 2023',
          'startTime': '5:30pm',
          'endTime': '7:00pm',
          'venue-image-src': '/static/carolina-classic-fair.jpg',
          'venue-url': 'https://www.facebook.com/carolinaclassicfair',
          'venue-calendar-file': '/static/calendar/carolina-classic-fair-20230930.ics',
          'venue-title': 'Carolina Classic Fair'
        },
        {
          'key': 9,
          'date': 'Sept 9th, 2023',
          'startTime': '8:00pm',
          'endTime': '11:00pm',
          'venue-image-src': '/static/follys.jpg',
          'venue-url': 'https://www.facebook.com/follyskernersville',
          'venue-calendar-file': '/static/calendar/follys-20230909.ics',
          'venue-title': 'Folly\'s Snack and Draft'
        },
        {
          'key': 8,
          'date': 'August 25th, 2023',
          'startTime': '8:00pm',
          'endTime': '11:00pm',
          'venue-image-src': '/static/playground.jpg',
          'venue-url': 'https://www.facebook.com/ThePlaygroundGolfAndSportsBar',
          'venue-calendar-file': '/static/calendar/playground-20230825.ics',
          'venue-title': 'The Playground Golf and Sports Bar'
        },
        {
          'key': 7,
          'date': 'July 14th, 2023',
          'startTime': '6:30pm',
          'endTime': '9:30pm',
          'venue-image-src': '/static/roar.png',
          'venue-url': 'https://www.facebook.com/Roar.WinstonSalem',
          'venue-calendar-file': '/static/calendar/roar-20230714.ics',
          'venue-title': 'Roar'
        },
        {
          'key': 5,
          'date': 'August 19th, 2023',
          'startTime': '5:00pm',
          'endTime': '8:00pm',
          'venue-image-src': '/static/roar.png',
          'venue-url': 'https://www.facebook.com/Roar.WinstonSalem',
          'venue-calendar-file': '/static/calendar/roar-20230819.ics',
          'venue-title': 'Roar'
        },
        {
          'key': 4,
          'date': 'July 7th, 2023',
          'startTime': '7:00pm',
          'endTime': '9:00pm',
          'venue-image-src': '/static/ws-dash.jpg',
          'venue-url': 'https://www.milb.com/winston-salem',
          'venue-calendar-file': '/static/calendar/dash-20230707.ics',
          'venue-title': 'Winston-Salem Dash Baseball'
        },
        {
          'key': 3,
          'date': 'June 16th, 2023',
          'venue-image-src': '/static/tpc.jpg',
          'venue-url': 'https://www.facebook.com/tanglewoodpizza/',
          'venue-calendar-file': '/static/calendar/tanglewood-20230616.ics',
          'venue-title': 'Tanglewood Pizza Company'
        },
        {
          'key': 2,
          'date': 'June 1st, 2023',
          'startTime': '5:00pm',
          'endTime': '7:00pm',
          'venue-image-src': '/static/ws-dash.jpg',
          'venue-url': 'https://www.milb.com/winston-salem',
          'venue-calendar-file': '/static/calendar/dash-20230601.ics',
          'venue-title': 'Winston-Salem Dash Baseball'
        },
        {
          'key': 1,
          'date': 'May 27th, 2023',
          'venue-image-src': '/static/tpc.jpg',
          'venue-url': 'https://www.facebook.com/tanglewoodpizza/',
          'venue-calendar-file': '/static/calendar/tanglewood-20230527.ics',
          'venue-title': 'Tanglewood Pizza Company'
        },
        {
          'key': 111,
          'date': 'May 25th, 2023',
          'startTime': '6:00pm',
          'endTime': '9:00pm',
          'venue-image-src': '/static/incendiary.jpg',
          'venue-url': 'https://www.facebook.com/events/802449707668876',
          'venue-calendar-file': '/static/calendar/incendiary-20230525.ics',
          'venue-title': 'Incendiary Brewing Co'
        },
        {
          'key': 121,
          'date': 'April 1st, 2023',
          'startTime': '4:15pm',
          'endTime': '6:05pm',
          'venue-image-src': '/static/thunderbirds.jpg',
          'venue-url': 'https://www.facebook.com/carolinatbirds/',
          'venue-calendar-file': '/static/calendar/thunderbirds-20230401.ics',
          'venue-title': 'Carolina Thunderbirds'
        },
        {
          'key': 131,
          'date': 'February 18th, 2023',
          'startTime': '7:00pm',
          'endTime': '10:00pm',
          'venue-image-src': '/static/gas-hill-drinking-room.jpg',
          'venue-url': 'https://www.etix.com/ticket/p/2875747/wafer-thin-goryango-winstonsalem-the-ramkat',
          'venue-calendar-file': '/static/calendar/wafer-thin-goryango.ics',
          'venue-title': 'Gas Hill Drinking Room'
        },
        {
          'key': 141,
          'date': 'January 21st, 2023',
          'startTime': '7:00pm',
          'endTime': '10:00pm',
          'venue-image-src': '/static/follys.jpg',
          'venue-url': 'https://www.facebook.com/follyskernersville',
          'venue-calendar-file': '/static/calendar/follys-20230121.ics',
          'venue-title': 'Folly\'s Snack and Draft'
        },
        {
          'key': 999,
          'date': 'October 29th, 2022',
          'startTime': '3:45pm',
          'endTime': '5:45pm',
          'venue-image-src': '/static/thunderbirds.jpg',
          'venue-url': 'https://www.facebook.com/carolinatbirds/',
          'venue-calendar-file': '/static/calendar/thunderbirds-20221029.ics',
          'venue-title': 'Carolina Thunderbirds'
        },
        {
          'key': 9999,
          'date': 'October 22, 2022',
          'startTime': '6:00pm',
          'endTime': '9:00pm',
          'venue-image-src': '/static/tpc.jpg',
          'venue-url': 'https://www.facebook.com/tanglewoodpizza/',
          'venue-calendar-file': '/static/calendar/tanglewood-20221022.ics',
          'venue-title': 'Tanglewood Pizza Company'
        }
      ]
    })
  }
</script>

<style scoped>
  .gallery-card {
    transform-origin: center center 0;
    transition: .3s cubic-bezier(.25,.8,.50,1);
  }

  .fade-transition-leave,
  .fade-transition-leave-active,
  .fade-transition-leave-to {
    display: none;
  }
</style>
